import React from 'react'

import ObtainData from './ObtainData'
import Overview from './Overview'
import DataCleaning from './DataCleaning'
import DataVisualization from './DataVisualization'
import DataAnalysis from './DataAnalysis'
import OtherProjects from './OtherProjects'

export default function ProjectDetails() {
  return (
    <div>
        <Overview />
        <ObtainData />
        <DataCleaning />
        <DataVisualization />
        <DataAnalysis />
        <OtherProjects />
    </div>
  )
}
