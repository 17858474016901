import React from 'react'

export default function DataVisualization() {
  return (
    <div id = "dataVisualization" className = "project">
        <h2>Data Visualization</h2>
        <h3>World Map Visualization</h3>
        <div id="bodyText">
            ggplot2 includes the map_data package that has the latitude and longitude information 
            required to map different countries. However, some country names from map_data are different
             from my data so they need to be mapped the correct ones with the following code.
        </div>
        <img src={require("../../../asset/Project2/code4.webp")} alt="R Code" />
        <div id="bodyText">
            Then I filter the data to the latest year available and join my dataset with the map_data’s dataset 
            so I can add the coordination information to my dataset. As I will be reusing this code multiple 
            times, the code is embedded into a function that takes in one parameter to create a graph.
        </div>
        <div id="bodyText">
        Afterward, I will use ggplot to map all the data points.
        </div>
        <img src={require("../../../asset/Project2/code5.webp")} alt="R Code" />
        <div id="bodyText">
        And the final result is this chart that represents the total labor force of a country.
        </div>
        <img src={require("../../../asset/Project2/chart1.webp")} alt="World Map for Total Labor Force" />
        <h3>Regression Analysis</h3>
        <div id="bodyText">
            Regression analysis is perfect to determine the relationship between two factors as it can show how 
            much of the dependent variable can be explained from the independent variable, and also shows if that 
            relationship is statistically significant. 
            Fortunately, R is a programming language made by statisticians so it is not only possible but also 
            extremely simple to perform a regression analysis and graph the result.
        </div>
        <img src={require("../../../asset/Project2/code6.webp")} alt="R Code" />
        <div id="bodyText">
            The above code will perfrom a regression analysis between variable y, and variable x. 
            Creating a function for the regression analysis will allow me to quickly choose 2 variables 
            to perform the analysis. First, I will select the information needed for the analysis, and remove 
            its NA values to ensure the data entegrity.
        </div>
        <div id="bodyText">
            Then it is simply using the built in lm function in R to create the regression model and uses ggplot 
            to create the following graph.
        </div>
        <img src={require("../../../asset/Project2/chart2.webp")} alt="GDP vs. GNI Regression" />
        <div id="bodyText">
            {`The code with detailed explanations can be found `}
            <a href="https://github.com/quocminhngo/world-financial-analysis" target="__blank">
                here.
            </a>
        </div>
    </div>
  )
}
