import React from 'react'

import Button from "../Button"

export default function Hero() {
  return (
    <div className = "heroContainer">
        <div className="leftHero">
            <div className="intro">
                I’m <span className = "name">Quoc,</span>
                <br />
                <span className = "punchLine">a curious analyst searching for impactful data.</span>
            </div>

            <p className="description">
                Data has the power to change the world, and 
                I want to reveal that to everyone through effective analysis, 
                visualization, and story telling.
            </p>
            <div className="callToAction">
                <Button
                    name = "See My Work"
                    buttonType = "primaryButton" 
                    link = "#projects"  
                    />
                <Button
                    name = "Get in touch"
                    buttonType = "secondaryButton" 
                    link = "#contact"  
                    />
            </div>
        </div>
        <div className="rightHero">
            <img src={require("../../asset/Venn Diagram.png")} alt="Venn Diagram" className="vennDiagram"/>
            <img src={require("../../asset/Sprinkle.png")} alt="Sprinkle" className = "sprinkle"/>
            <img src={require("../../asset/Vector.png")} alt="Vector" className = "vector"/>

        </div>
    </div>
  )
}
