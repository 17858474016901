import React from 'react'

export default function DataVisualization() {
  return (
    <div id = "dataVisualization" className = "project">
        <h2>Data Visualization</h2>
        <div id="bodyText">
          Since the data has been cleaned to be suited for Tableau, all that is needed to be done now is to 
          import the data source into Tableau. I have stored each table in a separate Excel file for better 
          organization as there are 19 total tables with nearly 10 tabs in each file. So each table will be 
          imported into Tableau as a separate data source by using the Ctrl+D shortcut to add each individual 
          table.
        </div>
        <img src={require("../../../asset/Project3/tableau1.webp")} alt="Tableau" />
        <div id="bodyText">
        Then I selected the relevant tables with the drag and drop functionality of Tableau to create the 
        necessary visualizations for analysis. Since the project focuses on the trends over time, most of the
        visualizations will be time series charts so it is as simple as dragging the Year into columns with 
        the corresponding dependent variable into rows.
        </div>
        <img src={require("../../../asset/Project3/tableau2.webp")} alt="Tableau" />
        <div id="bodyText">
            Since the data is properly cleaned, it is pretty simple to create the visualizations. 
            However, some table has irrelevant data to the visualization to a custom set is needed
             to show only desired data points.
        </div>
        <img src={require("../../../asset/Project3/tableau3.webp")} alt="Tableau" />
    </div>
  )
}
