import React from 'react'

export default function DataAnalysis() {
  return (
    <div id = "dataAnalysis" className = "project">
        <h2>Data Analysis</h2>
        <div id="bodyText">
            The GDP world distribution comes out as expected with the United States and China 
            leading the world by a large margin in 2021. The majority of the world’s GDP is less than 
            5 trillion dollars while the United States and China has quadruple and triple that amount 
            respectively.
        </div>
        <img src={require("../../../asset/Project2/chart3.webp")} alt="GDP World Map" />
        <div id="bodyText">
          GDP is a measure of a country’s productivity, and having more people doesn’t necessarily yield a GDP 
          as indicated by the graph below. Despite having a positive slope, an R2 of 14.8% means that population 
          as a factor can only explain 14.8% of GDP. In other words, the productivity of each person from a 
          country can vary so widely that a country with a much lower population can outperform one with almost 
          4 times its population.
        </div>
        <img src={require("../../../asset/Project2/chart4.webp")} alt="GDP vs. Population Regression" />
        <div id="bodyText">
            GNI, gross national income, is another measure of economic activity. However, it measures the income of 
            all the citizens within a country, whether they earned the income domestically or internationally.
             As both GNI and GDP are both measures of economic activity, it almost perfectly correlates with GDP with a 
             slope of 0.99. An R2 of 99% is indication that the change in GNI explains for 99% of the change in
              GDP.
        </div>
        <img src={require("../../../asset/Project2/chart5.webp")} alt="GDP vs. GNI Regression" />
        <div id="bodyText">
          GDP growth has been consistent across most countries around the world with everybody being around the 5% mark. The
          only stand outs being China at close to 10% and South Sudan at around -5%.
        </div>
        <img src={require("../../../asset/Project2/chart6.webp")} alt="GDP Growth over past 30 years" />
        <h3>Take Aways</h3>
        <div id="bodyText">
          <li>
            The US economy is still doing extremely well compared to the rest of the world as indicated by 
            having the highest GDP in the world.
          </li>
          <li>
            Population doesn't correlate with productivity as some smaller and mid size countries are outperforming 
            countries with a large population.
          </li>
          <li>
            The world economy is moving in the right direction with only a handful of countries having a negative GDP growth for the 
            past 30 years.
          </li>
        </div>

    </div>
  )
}
