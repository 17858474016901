import React from 'react'

import Button from "./Button"

export default function ProjectCard({
    cardType, projectNumber, projectTitle, projectDetails, skills, technologies, buttonType, link, imageLink
}) {
    let listSkill = skills.map( skill => <li>{skill}</li>)
    let listTechnology = technologies.map( technology => <li>{technology}</li>)
  return (
    <div className = {cardType} id={projectNumber}>
        <div className="projectInformation">
            <h2>
            {projectTitle}
            </h2>
            <div className="projectDetails" id = "bodyText">
                {projectDetails}
            </div>
            <div className="keySummary">
                <div className="keySkills">
                    <div id="bodyBold">Key Skills</div>
                    <div className="skillList" id ="bodyText">
                        {listSkill}
                    </div>
                </div>
                <div className="keyTechnology">
                    <div id="bodyBold">Key Technology</div>
                    
                    <div className="techList" id = "bodyText">
                        {listTechnology}
                    </div>
                </div>
            </div>
            <Button
                name = "Learn More"
                buttonType = {buttonType} 
                link = {link} 
            />
        </div>

        <div className="projectImage">
            <a href={link}><img src={imageLink} alt="Project"/></a>
        </div>
    </div>
  )
}
