import React from 'react'

import ProjectDetails from './ProjectDetails'
import QuickLinks from '../../QuickLinks'


export default function ProjectContent() {
  return (
    <div className = "projectContent">
        <div className="lineSeperator" />
        <div className="flexWrapper">
            <ProjectDetails />

            <div className="linkWrapper">
                <QuickLinks />
            </div>
        </div>
    </div>
  )
}
