import React from 'react'

export default function QuickLinks() {
  return (
    <div className = "quickLinks">
        <div id="bodyBold">
            Quick Links
        </div>
        <div id="bodyText">
            <div className = "linkHover">
              <a href = "#overview" className = "linkHover">Project Overview</a>
            </div>
            <div className = "linkHover">
              <a href = "#obtainData" className = "linkHover">Obtaining the Data</a>
            </div>
            <div className = "linkHover">
              <a href = "#dataCleaning" className = "linkHover">Data Cleaning</a>
            </div>
            <div className = "linkHover">
              <a href = "#dataVisualization" className = "linkHover">Data Visualization</a>
            </div>
            <div className = "linkHover">
              <a href = "#dataAnalysis" className = "linkHover">Data Analysis</a>
            </div>
            <br />
            <div className = "linkHover">
              <a href = "#navbar" className = "linkHover">Back to Top</a>
            </div>
        </div>
    </div>

  )
}
