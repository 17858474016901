import React from 'react'

import Navbar from "../components/Navbar"
import AboutMe from "..//components/AboutMe"
import Footer from '..//components/Footer'

export default function AboutPage() {
  return (
    <div className = "aboutPage">
        <Navbar />
        <AboutMe />
        <Footer />
    </div>
  )
}
