import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        
        <nav id="navbar">
            <Link to="/">
                <img src={require("../asset/logo.png")} width="auto" height="50" alt="logo" className="logo" />
            </Link>
            <div id = "bodyText" className = "nav-link">
                <ul className = "linkHover">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-me">About</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>   
            </div>
        </nav>
        
    )
}