import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home"
import Project1 from "./pages/project1"
import Project2 from "./pages/project2"
import Project3 from "./pages/project3"
import AboutPage from "./pages/AboutPage"
import ContactPage from "./pages/ContactPage"

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element = {<Home />} />
        <Route exact path="/#projects" element = {<Home />} />
        <Route path="/new-york-housing-analysis" element = {<Project1 />} />
        <Route path="/world-financial-analysis" element = {<Project2 />} />
        <Route path="/financial-security-analysis" element = {<Project3 />} />
        <Route path="/about-me" element = {<AboutPage />} />
        <Route path="/contact" element = {<ContactPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
