import React from 'react'

export default function DataAnalysis() {
  return (
    <div id = "dataAnalysis" className = "project">
        <h2>Data Analysis</h2>
        <div id="bodyText">
            The median real income for all age groups appears to stagnate for the past 30 years. This means 
            that the raises in income have not managed to outpace inflation, indicating that the general 
            population has not been getting wealthier for the past 30 years. The older age groups seem to be 
            getting slight increases year over year but the amount is too little for a 30 year period.
        </div>
        <img src={require("../../../asset/Project3/chart1.webp")} alt="Income Chart" />
        <div id="bodyText">
            Over the years, people mainly save for liquidity and retirement with two interesting cross overs:
        </div>
        <div id="bodyText">
            <li>
            The first one is around 1996 when the Dot-Com bubble was happening, the years leading up to the bubble 
            bursting showed a significant increase in saving for retirement from under 20% to over 30%. As the economy was doing extremely well, people had more money to save for their retirement.
            In an economic boom, people tend to spend more instead of saving so it explains the dip in liquidity savings.
            </li>
            <li>
                Disaster struck in 2008 with the housing market crashing causing mass unemployment. During times 
                of hardship, people tend to cut down on spending and save more as uncertainty grows. However, the 
                downward trend of retirement savings is worrying because it will lead to more and more people 
                having to live on a fixed income after retirement, resulting in a very fragile society.
            </li>
        </div>

        <img src={require("../../../asset/Project3/chart2.webp")} alt="Saving Reason Chart" />
        <div id="bodyText">
            The number of people that save over all age groups has stayed consistent over the years in the 50% 
            to 60% range. This is very concerning as it is indicating that there are close to half that doesn’t 
            save at all. In an economic downturn such as now, the country is risking a massive snowball effect 
            that can cause an economic collapse if so many people suddenly lose their jobs and can no longer 
            afford to pay their bills.
        </div>
        <img src={require("../../../asset/Project3/chart3.webp")} alt="Saving Percentage Chart" />
        <div id="bodyText">
            There is a clear trend that indicates that younger people are taking on more debt compared to the 
            older age group. This makes sense as people are buying their first new car and first home at this 
            point in their life. However, the concerning thing is that people under 35 are leveraging more and 
            more. During good times when their income is stable, this won’t be much of a concern. However, it 
            could be very troublesome if we enter a recession and that income is suddenly cut. Then, they will 
            have to deal with a massive amount of debt that will balloon to a larger one if they don’t manage it 
            well.
        </div>
        <img src={require("../../../asset/Project3/chart4.webp")} alt="Leverage Ratio Chart" />
        <div id="bodyText">
            The debt break down has been extremely constant over the years with, unsurprisingly, close to 70% of the debt purpose allocated to buying a home, 
            either primary or an additional home. 
        </div>
        <img src={require("../../../asset/Project3/chart5.webp")} alt="Debt Purpose Chart" />
        <div id="bodyText">
            It is very concerning to see that less than a third of the population are doing extensive research before borrowing money. 
            The lack of research can prove to be costly when even a small increase in interate rate or loan length can have a major impact on 
            the total value of the loan.
        </div>
        <img src={require("../../../asset/Project3/chart6.webp")} alt="Intensity of Shopping Chart" />
        <h3>Take Aways</h3>
        <div id="bodyText">
            <li>People are over leveraged by saving too little and taking out too much debt.</li>
            <li>The spending power as well as spending behavior hasn't changed much in the past 30 years.</li>
            <li>The US needs to incorporate financial literacy into the education system to inform people 
            of all the potential upsides, as well as threats when they are taking on a loan.</li>
        </div>
    </div>
  )
}
