import React, {useState} from 'react'
import emailjs from '@emailjs/browser';

export default function Contact() {

    const [emailSentStatus, setEmailSentStatus] = useState(false)
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_o3xrrh6', 'template_vmf6a48', e.target, '34DdiY666mIF1bSkG')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
        setEmailSentStatus(true)
        };
    setTimeout(() => {
        setEmailSentStatus(false)
    }, 20000)
  return (
    <div className = "contact" id = "contact">
        <h1>Let's Connect</h1>
        <div id="bodyText">
            {`Drop me a line below! Or email me at `}
            <span className="email">
                <a href="mailto:quoc@quocminhngo.com">
                    quoc@quocminhngo.com
                </a>
            </span>
        </div>
        <div className="contactWrapper">
            <div className="formWrapper">
                <form className = "contactForm" onSubmit={sendEmail}>
                    <div className="formTitle">
                        Email*
                    </div>
                    <input type="email" className ="formField" name="sender_email"/>

                    <div className="formTitle">
                        Name*
                    </div>
                    <input type="name" className ="formField" name="sender_name"/>

                    <div className="formTitle">
                        Message*
                    </div>
                    <textarea type="message" className ="formField" id="messageField" name = "message"/>
                    <input type="submit" value="Submit" className = "primaryButton"/>
                    <div id="bodyText">
                        {emailSentStatus ? `Email sent successfully. I will contact you as soon as possible.` : null} 
                        
                    </div>
                </form>

            </div>
            <div className="formImage">
                <img src={require("../asset/envelope.webp")} alt="Envelope" />
            </div>
        </div>
    </div>
  )
}
