import React from 'react'

import Navbar from "../components/Navbar"
import Hero from "../components/Home/Hero"
import Projects from "../components/Home/Projects"
import AboutMe from "..//components/AboutMe"
import Contact from "..//components/Contact"
import Footer from '..//components/Footer'

export default function Home() {
    return (
        <div className = "Home">
            <Navbar />
            <Hero />
            <Projects />
            <AboutMe />
            <Contact />
            <Footer />
        </div>

    )
}