import React from 'react'

export default function DataAnalysis() {
  return (
    <div id = "dataAnalysis" className = "project">
        <h2>Data Analysis</h2>
        <div id="bodyText">
        {`The average price of 1 room apartment is over $4,000 a month, which only qualifies tenants with an annual salary
        of $160,000 or more. With the average salary of a New Yorker being `}
        <a href="https://dol.ny.gov/new-york-state-average-weekly-wage-nysaww-0" target ="__blank">$1,688.19 a week</a>
        , or just under $88,000 annually, 
        an average New Yorker cannot afford to live alone.
        </div>
        <img src={require("../../../asset/Project1/chart1.webp")} alt="Price Distribution for 1 room apartments" />
        <div id="bodyText">
            For those making an average salary, it is only possible to live with roommates in a 
            4 or 5 bedrooms property with 75% of rooms costing $1,875 or less. Meaning tenants only need to earn 
            $75,000 a year or less, which is much lower than the average of $88,000 that New Yorkers make.
        </div>
        <img src={require("../../../asset/Project1/chart3.webp")} alt="SQL Code" />
        <h3>Take Aways</h3>
        <div id="bodyText">
            <li>
                The increasing rent is creating a barrier for those who want to live in New York, which is 
                even more concerning for local New Yorkers as they are being pushed out of their home town.
            </li>
            <li>
                {`The only solution to rent pricing lies with supply and demand. With New York City having 
                skyrocketed demand with people `} 
                <a href="https://nypost.com/2022/06/22/crazy-nyc-rental-market-fuels-lines-to-see-tiny-apartment/" target="__blank">lining up for properties, </a>
                
                a decrease in price seems unlikely in the foreseeable future. But with the 40x Rent rule creating such a big entry 
                barrier, perhaps the demand will soon fall. Only then will the price fall, creating more opportunity for current residents,
                 as well as newcomers.
            </li>
        </div>
    </div>
  )
}
