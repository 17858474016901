import React from 'react'

export default function Overview() {
  return (
    <div id = "overview" className = "project">
        <h2>Project Overview</h2>
        <h3>Project Background</h3>
        <div id="bodyText">
            {`With 56% of Americans can’t afford a `}
            <a href="https://www.cnbc.com/2022/01/19/56percent-of-americans-cant-cover-a-1000-emergency-expense-with-savings.html" target = "__blank">$1,000 emergency expense</a>
            , American society is 
            walking on thin ice with the inevitable recession. So a deeper dive into Americans’ spending 
            habits can perhaps reveal why so many have to live from paycheck to paycheck. The goal of the 
            project is to uncover why so many people don’t have a lot of savings by looking at different potential 
            factors such as income, saving ratio, leverage ratio, debt purpose, etc.
        </div>
        <h3>Key Findings</h3>
        <div id="bodyText">
            <li>Median inflation adjusted income for all age groups has stayed relatively unchanged for 30 years.
            </li>
            <li>
                Saving ratio has also flat lined for 30 years with people most commonly saving for retirement or 
                to have liquidity.
            </li>
            <li>
                The most common reason why people take out debt is for a mortgage.
            </li>
            <li>
                Only a quarter of Americans do intensive research before taking on more debt.
            </li>
        </div>
    </div>
  )
}
