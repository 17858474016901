import React from 'react'

export default function Button({direct, link, name, buttonType}) {
    return (
        <a href={link} className ="buttonLink">
            <button className={buttonType}>
                {name}
            </button>
        </a>  
        
    )
}