import React from 'react'

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Hero from "../components/Projects/Project 2/Hero"
import ProjectContent from "../components/Projects/Project 2/ProjectContent"

export default function Project2() {
  return (
    <div className = 'projectPage'>
        <Navbar />
        <Hero />
        <ProjectContent />
        <Footer />
    </div>
  )
}
