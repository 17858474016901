import React from 'react'

export default function DataVisualization() {
  return (
    <div id = "dataVisualization" className = "project">
        <h2>Data Visualization</h2>
        <h3>Data Import</h3>
        <div id="bodyText">
            Since there are more than 12,000 data points, it is more efficient if we analyze data through SQL 
            as there is an added benefit of writing custom queries for visualization later on in Tableau.
            Also, it is possible to update the visualization with new data points by inserting data into the SQL
             database using Python.
        </div>
        <div id="bodyText">
            With that in mind, I created a database and a table for the data in SQL.
        </div>
        <img src={require("../../../asset/Project1/sql1.webp")} alt="SQL Code" />
        <div id="bodyText">
            Then I used the “Table Data Import Wizard” feature to import the csv file that I previously cleaned.
        </div>
        <img src={require("../../../asset/Project1/sql2.webp")} alt="SQL Import Table" />
        <h3>Data Visualization</h3>
        <div id="bodyText">
            {`There are many visualization tools, but Tableau will be the one I use for this project for 
            its effectiveness, and its great integration with both Python and SQL. `}
            {<a href="https://public.tableau.com/app/profile/zane8043/viz/ny_16656047386700/Dashboard1" target="__blank">The fully interactive dashboard </a>}
             below showcases the price distribution and summary of different types of properties in New York City.
        </div>
        <a href="https://public.tableau.com/app/profile/zane8043/viz/ny_16656047386700/Dashboard1" target="__blank">
            <img src={require("../../../asset/Project1/chart1.webp")} alt="SQL Import Table" />
        </a>
        <div id="bodyText">
            Additional cleaning is needed in SQL when the data shows that the distribution for 4 room apartments
            is cheaper compared to a 1 room apartment.
        </div>
        <img src={require("../../../asset/Project1/cleaning4.webp")} alt="4 Room Apartment Price Distribution" />
        <div id="bodyText">
            First, commit the table to ensure that no unwanted changes are made. Then add the rental_type 
            column and finally update it with the correct status so it can be used as a filter in Tableau. 
            This change was made after starting the visualization process but since Tableau is connected straight 
            to the MySQL database, any changes in the MySQL database will be reflected automatically to the Tableau 
            database.
        </div>
        <img src={require("../../../asset/Project1/cleaning5.webp")} alt="SQL Code" />
        <div id="bodyText">
            {`The full work process to make the dashboard can be found `}
            <a href="/data-visualization.pdf" target="__blank">
                here.
            </a>
        </div>
    </div>
  )
}
