import React from 'react'

import Button from '../../Button'

export default function Hero() {
  return (
    <div className ="projectHero" id = "hero">
        <div className="projectIntro">
            <h1>World Financial Analysis</h1>
            <div className="keySummary">
                <div className="keySkills">
                    <div id="bodyBold">Key Skills</div>
                    <div className="skillList" id ="bodyText">
                        <li>API</li>
                        <li>Data Mining</li>
                        <li>Data Visualization</li>
                    </div>
                </div>
                <div className="keyTechnology">
                    <div id="bodyBold">Key Technology</div>
                    
                    <div className="techList" id = "bodyText">
                        <li>R Programming Language</li>
                        <li>ggplot2</li>
                        <li>ddplyr</li>
                    </div>
                </div>
            </div>

            <Button 
                link = "#overview"
                buttonType = "primaryButton"
                name = "See More"
            />
        </div>
        <div className="projectHeroImage">
            <img src={require("../../../asset/project2.webp")} alt="Project 2" />
        </div>
    </div>
  )
}
