import React from 'react'

export default function ObtainData() {
  return (
    <div id = "obtainData" className = "project">
        <h2>Obtaining the Data</h2>
        <h3>Data Source</h3>
        <div id="bodyText">
          The Federal Reserve conducts a Survey of Consumer Finances every 3 years starting from 1989 to 
          help the public understand the condition of families in the United States. Fortunately, the Federal 
          Reserve has provided all of its findings so this project will leverage that for further analysis. 
        </div>
        <h3>Data Extraction Method</h3>
        <div id="bodyText">
            The Federal Reserve provides all its data in an Excel format so I just need to download it on
            its page.
        </div>
        <img src={require("../../../asset/Project3/fed.webp")} alt="Federal Reserve Page" />
    </div>
  )
}
