import React from 'react'

import Button from './Button'

export default function AboutMe() {
  return (
    <div className = "aboutMe" id = "about">
        <div className="profileImage">
            <img src={require("../asset/profile.webp")} alt="Profile" />
        </div>
        <div className="aboutMeDetails">
            <h2>About Me</h2>
            <div className="aboutMeText" id = "bodyText">
                Hi, I'm Quoc Ngo (pronounced as Kwok No, or you can call me Zane). I graduated from 
                Boston College in 2021 with an Information Systems and Finance double major. 
                My background enables me to understand the intricacy of both the business and the technical 
                aspects of a company. Since then, I have been working as an API Analyst that focuses on 
                database management, and quality control.
            </div>

            <h2>My Strength</h2>
            <div className="aboutMeText" id = "bodyText">
                With my business and technical background, I can add value to the company by:
                <li>Work and communicate effectively with cross-functional teams.</li>
                <li>Write efficient code for data retrieval, cleaning, and analysis.</li>
                <li>Create effective dashboards and presentations with my design experience.</li>
            </div>

            <div className="aboutMeHorizontalCard">
                <div className="hobbies">
                    <h2>My Hobbies</h2>
                    <div className="aboutMeText" id = "bodyText">
                        <div className="hobby">
                            <img src={require("../asset/hobby1.png")} alt="Language Icon" />
                            {` Learning new languages. I’m currently learning Korean.`}
                        </div>
                        <div className="hobby">
                            <img src={require("../asset/hobby2.png")} alt="Language Icon" />
                            {` Rubik’s cube. My personal best is 6 seconds.`}
                        </div>
                        <div className="hobby">
                            <img src={require("../asset/hobby3.png")} alt="Language Icon" />
                            {` Fitness. I regularly exercise and try to eat healthy.`}
                        </div>
                    </div>
                </div>
                <div className="skills">
                    <h2>Skills</h2>
                    <div className="skillWrapper">
                        <div className="skills1">
                            <div className="aboutMeText" id = "bodyText">
                                <li>Python</li>
                                <li>R</li>
                                <li>SQL</li>
                            </div>
                        </div>
                        <div className="skills2">
                            <div className="aboutMeText" id = "bodyText">
                                <li>Tableau</li>
                                <li>Javascript</li>
                                <li>UI/UX Design</li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
  )
}