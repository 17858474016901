import React from 'react'

export default function Footer() {
  return (
    <div className = "footer">
        <div className="icons">
            <a href="/contact">
              <img src={require("../asset/email.png")} alt="Email Icon" />
            </a>
            <a href="https://www.linkedin.com/in/quocminhngo/" target= "__blank">
              <img src={require("../asset/linkedin.png")} alt="Linkedin Icon" />
            </a>
            <a href="https://github.com/quocminhngo" target= "__blank">
              <img src={require("../asset/github.png")} alt="Github Icon" />
            </a>
        </div>
        <div className="copyright" id= "bodyText">
            ©{new Date().getFullYear()} Designed and Coded by Quoc Ngo
        </div>
    </div>
  )
}
