import React from 'react'

export default function DataCleaning() {
  return (
    <div id = "dataCleaning" className = "project">
        <h2>Data Cleaning</h2>
        <div id="bodyText">
            Even though the data is provided in an Excel file, a lot of cleaning is needed due to the wide data 
            format being unsuited for analysis and visualization. Each tab contains a specific summary of the survey 
            like pre-tax income. Within a summary, it is broken down further into different categories like age 
            group, and percentile of home. 
        </div>
        <img src={require("../../../asset/Project3/excel1.webp")} alt="Excel" />
        <div id="bodyText">
            Since each summary has multiple categories, each category needs to be separated into a pivot table like the one below 
            so it is easier for visualization in Tableau.
        </div>
        <img src={require("../../../asset/Project3/excel2.webp")} alt="Excel" />
        <div id="bodyText">
            As the survey has 19 total tables, it is highly repetitive to separate each category into its 
            separate pivot table. Luckily, Excel has a feature called Macro that solves this exact problem by 
            allowing the user to record specific actions that can be reused at another time. However, since each 
            table has different ranges from another, I will modify the Macro code in the backend that is written 
            in VBA.
        </div>
        <img src={require("../../../asset/Project3/vba1.webp")} alt="VBA Code" />
        <div id="bodyText">
            The code needs a simple setup using the concat function to combine the year, and the summary type 
            (mean, median, etc.). This setup is needed to give out the proper column name.
        </div>
        <div id="bodyText">
            Since each category is neatly separated by an empty row, the whole range can easily be selected with the first 10 lines of VBA code. The range will be 
            based on where the cursor is when executing the macro. So for the code to work correctly, 
            the cursor needs to be placed on the category name, such as A6 for Family characteristic. 
            Then with the keyboard shortcut Ctrl+Shift+U, which was set after the completion of the macro, 
            a pivot table like the one shown earlier will be created in a different tab. 
        </div>
        <img src={require("../../../asset/Project3/excel3.webp")} alt="Excel" />
        <div id="bodyText"></div>
    </div>
  )
}
