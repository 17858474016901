import React from 'react'

export default function ObtainData() {
  return (
    <div id = "obtainData" className = "project">
        <h2>Obtaining the Data</h2>
        <h3>Data Source</h3>
        <div id="bodyText">
          The World Bank Group is a global partnership that fights poverty through 
          sustainable solutions. They have provided their data openly through their API, 
          which will be leveraged to do this analysis.
        </div>
        <h3>Data Extraction Method</h3>
        <div id="bodyText">
          The World Bank Group has very good documentations on how to call their API, so I will use the 
          following code in R to extract the data from their API. The World Bank Group organizes their database 
          into indicators, each indicator represents a different dataset. Each dataset can be obtained by changing the indicator metric at the end of the URL variable. 
          Python can also achieve the same result as R, but since each dataset contains over 10,000 data points, the 
          API call in R is much simpler and more efficient as R is vector based. Hence, an additional loop is 
          not needed to put the data in each column variable to later form a data frame, significantly lowering 
          the execution time.
        </div>
        <img src={require("../../../asset/Project2/code1.webp")} alt="R Code" />
    </div>
  )
}
