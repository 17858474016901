import React from 'react'

import ProjectCard from "../ProjectCard"

export default function Projects() {
  return (
    <div className = "projects" id = "projects">
        <h1>Projects</h1>
        <ProjectCard 
            cardType = "horizontalCard"
            projectNumber = "project1"
            projectTitle = "New York Housing Analysis"
            projectDetails = {`A deep dive into the New York housing market to determine its affordability by 
                web scraping over 10,000 data points from Rentals.com`}
            skills = {["Data Pipeline", "Web Scraping", "Data Cleaning"]}
            technologies = {["Python", "MySQL", "Tableau"]}
            buttonType = "primaryButton"
            link = "/new-york-housing-analysis"
            imageLink = {require("../../asset/project1.webp")}
        />
        <ProjectCard 
            cardType = "horizontalCard"
            projectNumber = "project2"
            projectTitle = "World Financial Analysis"
            projectDetails = {`Leverage R to visualize different financial factors that contribute to the wealth of a country
                by using the World Bank Group's API as a data source.`}
            skills = {["API", "Data Mining", "Data Visualization"]}
            technologies = {["R", "ggplot2", "ddplyr"]}
            buttonType = "project2Button"
            link = "/world-financial-analysis"
            imageLink = {require("../../asset/project2.webp")}
        />
        <ProjectCard 
            cardType = "horizontalCard"
            projectNumber = "project3"
            projectTitle = "Financial Security Analysis"
            projectDetails = {`Determine the overall state of personal financial security of an average
                US citizen by using public survey data from the Federal Reserve.`}
            skills = {["Data Wrangling", "Data Cleaning", "Data Visualization"]}
            technologies = {["Excel", "Tableau", "VBA"]}
            buttonType = "project3Button"
            link = "/financial-security-analysis"
            imageLink = {require("../../asset/project3.webp")}
        />
    </div>
  )
}
