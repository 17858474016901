import React from 'react'

import ProjectCard from '../../ProjectCard'
export default function OtherProjects() {
  return (
    <div className = "otherProject" id = "otherProjects">
        <h2>Other Projects</h2>
        <div className="otherProjectWrapper">
            <ProjectCard 
                cardType = "verticalCard"
                projectNumber = "project1"
                projectTitle = "New York Housing Analysis"
                projectDetails = {`A deep dive into the New York housing market to determine its affordability by 
                web scraping over 10,000 data points from Rentals.com`}
                skills = {["Data Analysis", "Data Mining", "Data Visualization"]}
                technologies = {["Excel", "Tableau", "VBA"]}
                buttonType = "primaryButton"
                link = "/new-york-housing-analysis"
                imageLink = {require("../../../asset/project1.webp")}
            />
            <ProjectCard 
                cardType = "verticalCard"
                projectNumber = "project2"
                projectTitle = "World Financial Analysis"
                projectDetails = {`Leverage R to visualize different financial factors that contribute to the wealth of a country
                by using the World Bank Group's API as a data source.`}
                skills = {["Data Analysis", "Data Mining", "Data Visualization"]}
                technologies = {["Excel", "Tableau", "VBA"]}
                buttonType = "project2Button"
                link = "/world-financial-analysis"
                imageLink = {require("../../../asset/project2.webp")}
            />


        </div>


    </div>
  )
}
