import React from 'react'

export default function Overview() {
  return (
    <div id = "overview" className = "project">
        <h2>Project Overview</h2>
        <h3>Project Background</h3>
        <div id="bodyText">
            The long lasting pandemic has taken a toll on our economy with a snowball effect that causes 
            record high inflation, and mass lay-offs. With the looming recession, the goal of the project is to
            determine how the United States is doing financially in comparison to other countries around the world. From then, figure out if there are factors that correlate with how well a country 
            is doing financially, and visualize the findings. All the necessary data will be collected from the World Bank Group’s API.
        </div>
        <h3>Key Findings</h3>
        <div id="bodyText">
            <li>The data indicates there is little to no correlation between GDP and population.
            </li>
            <li>
                On average, most countries have had positive GDP growths in the past 20 years.
            </li>
            <li>
                GDP and GNI are the only metrics that correlate with one another. 
            </li>
        </div>
    </div>
  )
}
