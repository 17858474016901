import React from 'react'

import ProjectCard from '../../ProjectCard'
export default function OtherProjects() {
  return (
    <div className = "otherProject" id = "otherProjects">
        <h2>Other Projects</h2>
        <div className="otherProjectWrapper">
            <ProjectCard 
                cardType = "verticalCard"
                projectNumber = "project2"
                projectTitle = "World Financial Analysis"
                projectDetails = {`Leverage R to visualize different financial factors that contribute to the wealth of a country
                by using the World Bank Group's API as a data source.`}
                skills = {["Data Analysis", "Data Mining", "Data Visualization"]}
                technologies = {["Excel", "Tableau", "VBA"]}
                buttonType = "project2Button"
                link = "/world-financial-analysis"
                imageLink = {require("../../../asset/project2.webp")}
            />

            <ProjectCard 
                cardType = "verticalCard"
                projectNumber = "project3"
                projectTitle = "Financial Security Analysis"
                projectDetails = {`Determine the overall state of personal financial security of an average
                US citizen by using public survey data from the Federal Reserve.`}
                skills = {["Data Analysis", "Data Mining", "Data Visualization"]}
                technologies = {["Excel", "Tableau", "VBA"]}
                buttonType = "project3Button"
                link = "/financial-security-analysis"
                imageLink = {require("../../../asset/project3.webp")}
            />
        </div>


    </div>
  )
}
