import React from 'react'

export default function DataCleaning() {
  return (
    <div id = "dataCleaning" className = "project">
        <h2>Data Cleaning</h2>
        <div id="bodyText">
            Since the data is coming from the World Bank, there is minimal cleaning that 
            needs to be done. However, there are some key points that need to be address:
            <li>
                NA values will not be removed in the initial cleaning as each entry has multiple 
                metrics and not all will be NA, so the data will be underrepresented if NA values are 
                removed from the start. Instead, it will be removed when 2 variables are in isolation, 
                such as when a regression analysis is performed.
            </li>
            <li>
                The World Bank Group’s country data contains regional data as well, which is not the focus 
                of this analysis so they will be removed. As the data comes from one source, 
                the individual countries' data consistently starts at the 50th entry for all datasets.
            </li>
        </div>
        <img src={require("../../../asset/Project2/code2.webp")} alt="R Code" />
        <div id="bodyText">
            The above code is used to clean the data. I have previously stored the data obtained from the World 
            Bank Group’s API in a csv file. So the first few lines of code is to retrieve the data from 
            individual csv files. After obtaining a list of unique countries, it is fairly simple to filter out 
            the individual countries’ data as it will start from the 50th position as seen below.
        </div>
        <img src={require("../../../asset/Project2/code3.webp")} alt="R Code" />
        <div id="bodyText">
            {`The code with detailed explainations can be found `}
            <a href="https://github.com/quocminhngo/world-financial-analysis" target="__blank">
                here.
            </a>
        </div>
    </div>
  )
}
