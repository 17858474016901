import React from 'react'

export default function ObtainData() {
  return (
    <div id = "obtainData" className = "project">
        <h2>Obtaining the Data</h2>
        <h3>Data Source</h3>
        <div id="bodyText">
            It is common for an average user to go to a large rental website when searching for a new apartment. 
            So I will be looking at data from Rentals.com, a popular website with thousands of 
            listing in New York City.
        </div>
        <img src={require("../../../asset/Project1/rentals.webp")} alt="Rentals.com Search Page" />
        <h3>Data Extraction Method</h3>
        <div id="bodyText">
            Create an ETL data pipeline by web scraping all the currently available price, bathroom, bedroom, 
            and address data of every rental unit from Rentals.com using Python Selenium library. Then export 
            the data as a csv file for transformation in Microsoft Excel before loading the cleaned data into a 
            MySQL database for visualization in a BI tool.
        </div>
        <div id="bodyText">
          Selenium uses a Chromium browser to know where to scrape information. 
          The following Python code will open a chromium browser located on my local computer 
          and direct it to Rentals.com's website that 
          displays New York City's apartment listings.
        </div>
        <img src={require("../../../asset/Project1/jupyter1.webp")} alt="Python Code" />
        <div id="bodyText">
          Then I loop through each listing on every page to extract the price, address, bedroom, and bathroom 
          information from their corresponding HTML tag.
        </div>
        <img src={require("../../../asset/Project1/jupyter2.webp")} alt="Python Code" />
        <div id="bodyText">
            {`You can find the code with a line-by-line explanation `}
            <a href="https://github.com/quocminhngo/New-York-Housing-Analysis" target="__blank">here</a>.
        </div>
    </div>
  )
}
