import React from 'react'

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Hero from "../components/Projects/Project 3/Hero"
import ProjectContent from "../components/Projects/Project 3/ProjectContent"

export default function Project3() {
  return (
    <div className = 'projectPage'>
        <Navbar />
        <Hero />
        <ProjectContent />
        <Footer />
    </div>
  )
}
