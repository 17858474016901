import React from 'react'

export default function DataCleaning() {
  return (
    <div id = "dataCleaning" className = "project">
        <h2>Data Cleaning</h2>
        <div id="bodyText">
            I tried to keep the data as clean as possible during the data extraction process. 
            However, there are some steps that I took to ensure the integrity of the data using Excel:
            <li>
                Check for duplicates by using the “Remove Duplicates” feature in Excel. Duplicates occur when a 
                property is placed as an ad multiple times.
                <img src={require("../../../asset/Project1/cleaning1.webp")} alt="Excel Remove Duplicates" />
            </li>
            <li>
                Remove text information for price information as they should be numeric.
                <img src={require("../../../asset/Project1/cleaning2.webp")} alt="Excel Remove Text Information" />
            </li>
            <li>Apply a filter to check for any blank cells, and any potential outliers.
                <img src={require("../../../asset/Project1/cleaning3.webp")} alt="Excel Apply Filter" />
            </li>
        </div>
        <div id="bodyText">
            {`The detailed data validation and data cleaning process can be found `}
            <a href="/data-validation-and-cleaning.pdf" target="__blank">
                here.
            </a>
        </div>
    </div>
  )
}
