import React from 'react'

export default function Overview() {
  return (
    <div id = "overview" className = "project">
        <h2>Project Overview</h2>
        <h3>Project Background</h3>
        <div id="bodyText">
            {`Moving to New York is a dream move for many of us. Unfortunately, its affordability was shattered 
            to pieces with the implementation of the 40x Rent rule, which states that renters’ annual salary 
            needs to be 40 times the monthly rent. With the overwhelming popularity of the rule, the goal of the 
            project is to determine if an average New Yorker's salary can still afford a room in New York using `} 
            {<a href= "https://www.chase.com/personal/banking/education/budgeting-saving/how-much-income-should-go-to-rent" target = "__blank">
             Chase’s budgeting guideline
            </a>}  that rent should be no more than 30% of your income. 
        </div>
        <h3>Key Findings</h3>
        <div id="bodyText">
            <li>New York has an average rent of over $4,000 a month for a single-bedroom apartment, 
                making it only affordable for those making $160,000 or more a year.
            </li>
            <li>
                Renting a single room in a 4 or 5 room apartment is much more 
                affordable at below $1,500 per month.
            </li>
            <li>
                For an average salary maker, the only probable solution is to have roommates to be able to afford
                the high cost of housing in New York.
            </li>
        </div>
    </div>
  )
}
