import React from 'react'

import Button from '../../Button'

export default function Hero() {
  return (
    <div className ="projectHero" id = "hero">
        <div className="projectIntro">
            <h1>New York Housing Analysis</h1>
            <div className="keySummary">
                <div className="keySkills">
                    <div id="bodyBold">Key Skills</div>
                    <div className="skillList" id ="bodyText">
                        <li>Data Pipeline</li>
                        <li>Web Scraping</li>
                        <li>Data Cleaning</li>
                        <li>Data Visualization</li>
                    </div>
                </div>
                <div className="keyTechnology">
                    <div id="bodyBold">Key Technology</div>
                    
                    <div className="techList" id = "bodyText">
                        <li>Python</li>
                        <li>MySQL</li>
                        <li>Tableau</li>
                        <li>Excel</li>
                    </div>
                </div>
            </div>

            <Button 
                link = "#overview"
                buttonType = "primaryButton"
                name = "See More"
            />
        </div>
        <div className="projectHeroImage">
            <img src={require("../../../asset/project1.webp")} alt="Project 1" />
        </div>
    </div>
  )
}
